import React from 'react'
import Axios from '../../utilities/axios'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'
import { useHistory } from 'react-router'
import swal from 'sweetalert'
import Filtersearch from '../commonComponents/Filtersearch'
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

const Castudents = () => {
  const [rows, setRows] = React.useState([])
  const [dummy, setDummy] = React.useState([])
  const [page, setPage] = React.useState(0);
  const [pageSize, setPageSize] = React.useState(10);
  const [rowCount, setRowCount] = React.useState(0);
  const [loading, setLoading] = React.useState(false)
  const [courseStatus, setcourseStatus] = React.useState({
    status: "active",
  });

  const uid = localStorage.getItem('uid');

  React.useEffect(() => {
    setRows([]);
    setLoading(true)
    const payload = {
      course_status: courseStatus.status,
      user_id: uid,
      page : page+1,
      pageSize : pageSize
    };
    Axios.post(`student/getallstudents_under_courseadmin`, payload)
      .then((res) => {
        let s = res?.data?.data?.[0];
        setRowCount(res?.data?.total_count ? res?.data?.total_count : 0);
        if (s == false) {
          setRows([]);
          setLoading(false);
          setDummy(s);

        } else {
          s.map((item, index) => {
            item.uid = item.id;
            item.id = index + 1;
            return item;
          });
          setLoading(false);
          setRows(s);
          setDummy(s);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [courseStatus,page, pageSize]);


  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      width: 20,
      hide: true,
    },
    {
      field: 'uid',
      headerName: 'Student ID',
      width: 180,
    },
    {
      field: 'fullname',
      headerName: 'Student Name',
      width: 200,
    },
    {
      field: 'student_level_status',
      headerName: 'Student Status',
      width: 200,
    },
    {
      field: 'dob',
      headerName: 'Date of Birth',
      width: 180,
    },
    {
      field: 'register_at',
      headerName: 'Register date',
      width: 200,
    },
    // {
    //   field: 'address',
    //   headerName: 'Address',
    //   width: 180,
    // },
    {
      field: 'course_name',
      headerName: 'Course Name',
      width: 200,
    },
    {
      field: 'language',
      headerName: 'Language',
      width: 200,
    },
    {
      field: 'teacher',
      headerName: 'Teacher Name',
      width: 190,
    },
    {
      field: 'class_name',
      headerName: 'Class Name',
      width: 200,
    },
    {
      field: 'slot_time',
      headerName: 'Class Time',
      width: 200,
    },
    {
      field: 'phone_number',
      headerName: 'Mobile',
      width: 140,
    },
    {
      field: 'email_id',
      headerName: 'E-mail ID',
      width: 190,
    },
    {
      field: 'address',
      headerName: 'Address Line 1',
      width: 350,
    },
    {
      field: 'address2',
      headerName: 'Address Line 2',
      width: 350,
    },
    {
      field: 'city',
      headerName: 'City',
      width: 200,
    },
    {
      field: 'state',
      headerName: 'State',
      width: 200,
    },
    {
      field: 'country',
      headerName: 'Country',
      width: 200,
    },
    {
      field: 'pincode',
      headerName: 'Pincode',
      width: 200,
    },
    {
      field: 'region',
      headerName: 'Region',
      width: 200,
    },
    {
      field: 'timezone',
      headerName: 'Time-Zone',
      width: 200,
    },
    {
      field: 'registration_status',
      headerName: 'Registration Status',
      width: 250,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      width: 200,
    },
    {
      field: 'pay_status',
      headerName: 'Payment Status',
      width: 200,
    },
    {
      field: 'payment_date',
      headerName: 'Payment Date',
      width: 200,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      type: 'actions',
      width: 350,
      sortable: false,
      renderCell: RenderDetailsButton,
      disableClickEventBubbling: true,
    },
  ]

  return (
    <>
      <div className="card height-auto">
        <div className="card-body">
          <div className="table-responsive">
            <div className="row mt-3">
              <div className="col-md-6 col-xl-4  offset-md-4 form-group" >
                <FormControl >
                  <InputLabel id="course-duration-select-helper-label">
                    Select Category
                  </InputLabel>
                  <Select
                    labelId="course-duration-select-helper-label"
                    id="course-duration-select-helper"
                    // value={details?.course_duration}
                    value={courseStatus?.status} // Set the default value to "active"
                    // value={"active"} // Set the default value to "active"

                    label="Course Duration"
                    // disabled={status}
                    onChange={(e)=>setcourseStatus({ status: e.target.value })}
                  // disabled={
                  //   history.location.pathname.includes("/editcourse/")
                  //     ? status
                  //     : false
                  // }
                  >
                    {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem> */}
                    <MenuItem value={"active"}>Active Courses</MenuItem>
                    <MenuItem value={"inactive"}>Inactive Courses</MenuItem>
                    <MenuItem value={"all"}>All Courses</MenuItem>
                    {/* <MenuItem value={"yearly"}>Yearly</MenuItem> */}
                  </Select>
                </FormControl>

              </div>
              <div className="col-md-4 col-xl-4   form-group" >
                <Filtersearch dummy={dummy} setRows={setRows} />
              </div>
              {/* <div className="col-md-4 mt-4 "> */}

              {/* </div> */}
            </div>
            <DataGrid
              components={{
                Toolbar: GridToolbar,
              }}
              rows={rows}
              columns={columns}
              loading={loading}
              autoHeight={true}
              pagination
              paginationMode="server"
              rowCount={rowCount}
              pageSize={pageSize}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[10, 20, 30, 40, 50, 100]}
            />
          </div>

        </div>
      </div>
    </>
  )
}

export default Castudents

const RenderDetailsButton = (params) => {
  let history = useHistory()
  const onDelete = (params) => {
    swal({
      title: 'Are you sure?',
      text: 'Once deleted, you will not be able to recover!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let uid = localStorage.getItem('uid')
        let role = localStorage.getItem('role')
        let data = {
          student_id: `${params.row.uid}`,
          user_id: uid,
          role,
        }
        Axios.post('student/delete_student', data)
          .then((res) => {
            if (res.data.message === 'not allowed to delete') {
              swal('Your Not Allowed to Delete!', {
                icon: 'warning',
              })
            } else {
              swal('Student has been deleted!', {
                icon: 'success',
              })
              res?.data?.data?.map((item, index) => {
                item.uid = item.id
                item.id = index + 1
                return item
              })
              params.api.setRows(res.data.data)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }
  const onUnassociation = (params) => {
    swal({
      title: 'Are you sure?',
      text: 'Once unassociated, you will not be able to associate again!',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let uid = window.localStorage.getItem('uid')
        let myData = {
          student_id: params.row.uid,
          course_id: params.row.course_id,
          user_id: uid,
          role: 'courseadmin',
        }
        Axios.post('student/remove_student_association', myData)
          .then((res) => {
            if (res.data.message === 'success') {
              swal('Unassociated!', {
                icon: 'success',
              })
              res?.data?.result?.map((item, index) => {
                item.uid = item.id
                item.id = index + 1
                return item
              })
              params.api.setRows(res.data.result)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      }
    })
  }
  const onView = (params) => {
    history.push(`/Single Student/${params.row.uid}`)
  }
  return (
    <strong>
      <button
        className="btn btn-success"
        onClick={() => {
          onView(params)
        }}
      >
        <i className="fas fa-user"></i> View Profile
      </button>{' '}
      <button
        className="btn btn-danger"
        onClick={() => {
          onDelete(params)
        }}
      >
        <i className="fa-solid fa-trash"></i> Delete Student
      </button>{' '}
      <button
        className="btn btn-info"
        onClick={() => {
          onUnassociation(params)
        }}
      >
        <i class="fa-solid fa-user-xmark"></i> Unassociate Student
      </button>
    </strong>
  )
}
